<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body"><user-info /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <e-chart title="Performa Gudang" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { defineAsyncComponent } from 'vue'
import EChart from '@/components/charts/EChartPerformanceGudang'
export default {
  components: {
    UserInfo: defineAsyncComponent({
      loader: () => import('@/components/widgets/UserInfo'),
      loadingComponent: Loading,
    }),
    EChart,
  },
}
</script>

<style></style>
