<template>
  <v-chart
    class="chartz"
    ref="chart"
    :option="option"
    :manual-update="true"
    autoresize
    :loading="loading"
  />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components'
import { ref, defineComponent, reactive, onMounted, toRefs, watch } from 'vue'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  LineChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
])
import apiClient from '@/services/axios'

export default defineComponent({
  name: 'EChartDashboardPerformance',
  components: {
    VChart,
  },
  props: {
    title: {
      default: 'Performance Gudang',
      type: String,
    },
    timestamp: {
      type: Object,
      default: () => ({
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      }),
    },
  },
  setup: props => {
    const series = reactive({
      sale_In: [],
      sale_out: [],
      date: [],
      level: [],
      source: [],
    })
    const loading = ref(false)
    const chart = ref()

    const option = ref({
      title: {
        text: props.title,
        left: 'center',
        textStyle: {
          overflow: 'break',
          fontSize: '1rem',
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      textStyle: {
        overflow: 'break',
        fontSize: '0.8rem',
        width: 120,
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 10,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        formatter: function(params) {
          const formatter = Intl.NumberFormat('id-ID')
          return `
      Tanggal : ${params[0].name} <br />
      Level Stock : ${Number(params[0].value).toFixed(2)}% <br />
      Stock : ${formatter.format(series.source.find(f => f.date === params[0].name).stock)} <br />
      Sale In : ${formatter.format(params[1].value)} <br />
      Sale Out : ${formatter.format(params[2].value)} <br />
      `
        },
      },
      legend: {
        // type: 'scroll',
        bottom: 0,
        // padding: [0, 0, 0, 0],
      },
      xAxis: [
        {
          type: 'category',
          data: series.date,
          axisLabel: {
            formatter: function(a) {
              return new Date(a)
                .getDate()
                .toString()
                .padStart(2, '0')
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Total Volume Penjualan',
          nameLocation: 'middle',
          nameGap: 43,
          axisLabel: {
            formatter: function(a) {
              const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
              return formatter.format(a)
            },
          },
        },
        {
          type: 'value',
          axisLabel: {
            formatter: function(a) {
              return a + ' %'
            },
          },
        },
      ],
      series: [
        {
          name: 'Level Stock (%)',
          type: 'line',
          smooth: true,
          yAxisIndex: 1,
          data: series.level,
        },
        {
          name: 'Sell In',
          type: 'line',
          smooth: true,
          data: series.sale_In,
        },
        {
          name: 'Sell Out',
          type: 'line',
          smooth: true,
          data: series.sale_out,
        },
      ],
      // dataZoom: [
      //   {
      //     type: 'slider',
      //   },
      // ],
      media: [
        {
          query: {
            maxWidth: 300, // when container width is smaller than 300
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 230,
                fontSize: '.9rem',
              },
            },
          },
        },
        {
          query: {
            minWidth: 300, // when container width is smaller than 300
            maxWidth: 500, // when container width is smaller than 500
          },
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'break',
                width: 300,
              },
            },
          },
        },
        {
          option: {
            title: {
              text: props.title,
              left: 'center',
              textStyle: {
                overflow: 'none',
              },
            },
          },
        },
      ],
    })
    const fetchData = (start_date, end_date) => {
      loading.value = true
      apiClient
        .get(`/api/dashboard/distributor`, {
          params: { start_date, end_date },
        })
        .then(response => {
          loading.value = true
          const data = response.data
          series.sale_In.splice(0, series.sale_In.length)
          series.sale_In.push(...data.map(i => parseFloat(i.sale_in).toFixed(2)))

          series.sale_out.splice(0, series.sale_out.length)
          series.sale_out.push(...data.map(i => parseFloat(i.sale_out).toFixed(2)))

          series.level.splice(0, series.level.length)
          series.level.push(...data.map(i => parseFloat(i.level).toFixed(2)))

          series.date.splice(0, series.date.length)
          series.date.push(...data.map(i => i.date))

          series.source = data

          chart.value.setOption(option.value)
        })
        .catch(error => console.error(error))
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData(props.timestamp.startDate, props.timestamp.endDate)
    })

    watch(
      () => ({ ...props.timestamp }),
      (n, o) => {
        fetchData(n.startDate, n.endDate)
      },
    )

    return { option, loading, chart }
  },
})
</script>

<style>
.chartz {
  height: 300px;
}
</style>
